/* eslint-disable max-lines */
import { isDefined } from "@clipboard-health/util-ts";
import { InterviewStatus } from "@src/appV2/Shifts/MyShifts/api/useFetchPaginatedInterviews";
import { addDays, format, isAfter, parseISO, roundToNearestMinutes } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import { formatCentsAsUsd } from "../../lib/Money/utils/currency";
import { type Worker } from "../../Worker/api/types";
import {
  type PlacementCandidate,
  PlacementCandidateStatus,
  ShiftNameType,
} from "../PlacementCandidate/types";
import { type PlacementDetail } from "./api/useGetPlacement";
import {
  PLACEMENT_ACTION_TYPE,
  type PrimaryActionProps,
} from "./components/PlacementPrimaryAction";
import {
  type GetPlacementsForWorkplaceQuery,
  PlacementApplicationStatus,
  type PlacementIncludedInterview,
} from "./types/fetchPlacements.schema";
import { type PayRate } from "./types/payRate";

export const DEFAULT_DISTANCE_FILTER_IN_MILES = 150;

export function hasAppliedToPlacement(placement?: PlacementDetail) {
  return (placement?.applications?.length ?? 0) > 0;
}

export function isApplicationCompleted(placement?: PlacementDetail) {
  const completedStatuses = [PlacementApplicationStatus.REJECTED, PlacementApplicationStatus.HIRED];
  return Boolean(
    placement?.applications?.[0]?.attributes?.status &&
      completedStatuses.includes(placement.applications[0].attributes.status)
  );
}

export function interviewRequired(placement?: PlacementDetail) {
  return Boolean(placement?.interviewSettings?.attributes?.scheduleInterviews);
}

export function hasBookedInterview(placement?: PlacementDetail) {
  const hasAtLeastOneApplication = (placement?.applications?.length ?? 0) > 0;
  const isInterviewBooked =
    placement?.interviews?.some(
      (interview) => interview.attributes.status === InterviewStatus.BOOKED
    ) ?? false;
  return hasAtLeastOneApplication && isInterviewBooked;
}

export function hasCancelledInterview(placement?: PlacementDetail) {
  return (
    interviewRequired(placement) &&
    hasAppliedToPlacement(placement) &&
    placement?.interviews?.some(
      (interview) => interview.attributes.status === InterviewStatus.CANCELLED
    )
  );
}

export function getDefaultShiftTypeForDifferential(shiftTypes: ShiftNameType[]) {
  // choose the shift type the worker is most likely to get by prioritizing the most common shift types
  // see https://linear.app/clipboardhealth/issue/TF-1769/change-pay-database-schema-table-design
  return [ShiftNameType.AM, ShiftNameType.PM, ShiftNameType.NOC].find((shiftType) =>
    shiftTypes.includes(shiftType)
  );
}

export function getShiftTypePayDifferential(payRate: PayRate, shiftTypes?: ShiftNameType[]) {
  if (!shiftTypes || shiftTypes.length === 0) {
    return 0;
  }

  const chosenType = getDefaultShiftTypeForDifferential(shiftTypes);

  if (!chosenType) {
    return 0;
  }

  return payRate.shiftTypeDiff?.[chosenType] ?? 0;
}

export function getBenefitsPayDifferential(payRate: PayRate, withBenefits: boolean) {
  const benefitsDiff = isDefined(payRate.benefitsDiff) ? payRate.benefitsDiff : 0;

  // a rate which includes benefits is lower than a rate which does not include benefits
  // (since the employer is paying for the benefits)
  const rateIncludesBenefits = isDefined(payRate.rateIncludesBenefits)
    ? payRate.rateIncludesBenefits
    : true;

  if (withBenefits && rateIncludesBenefits) {
    return 0;
  }

  if (withBenefits && !rateIncludesBenefits) {
    return -benefitsDiff;
  }

  // a worker can trade away benefits for more pay
  if (!withBenefits && rateIncludesBenefits) {
    return benefitsDiff;
  }

  if (!withBenefits && !rateIncludesBenefits) {
    return 0;
  }

  throw new Error("All logical cases should have been covered");
}

export function getWeekendPayDifferential(payRate: PayRate, isWeekendShift: boolean) {
  return isWeekendShift ? payRate.weekendDiff ?? 0 : 0;
}

export interface GetDifferentialJobTraits {
  shiftTypes?: ShiftNameType[];
  withBenefits: boolean;
  // note that this is not quite the same as a weekend_warrior
  // weekend_warrior means working back to back double shifts on the weekend
  // whereas this is just a shift on the weekend
  isWeekendShift: boolean;
}

export function getOverallPayDifferential(payRate: PayRate, jobTraits: GetDifferentialJobTraits) {
  return (
    getShiftTypePayDifferential(payRate, jobTraits.shiftTypes) +
    getBenefitsPayDifferential(payRate, jobTraits.withBenefits) +
    getWeekendPayDifferential(payRate, jobTraits.isWeekendShift)
  );
}

export function applyOverallDifferentialToPayRate(
  pay: number,
  payRate: PayRate,
  jobTraits: GetDifferentialJobTraits
) {
  return pay + getOverallPayDifferential(payRate, jobTraits);
}

export function getOverallPayBreakdown(
  payRate: PayRate,
  differentialTrails: GetDifferentialJobTraits
): (
  | {
      type: "range";
      overallPayMin: number;
      overallPayMax: number;
    }
  | {
      type: "single";
      overallPay: number;
    }
) & {
  overallPayDifferential: number;
  shiftTypePayDifferential: number;
  benefitsPayDifferential: number;
  weekendPayDifferential: number;
} {
  const overallPayDifferential = getOverallPayDifferential(payRate, differentialTrails);

  const shiftTypePayDifferential = getShiftTypePayDifferential(
    payRate,
    differentialTrails.shiftTypes
  );
  const benefitsPayDifferential = getBenefitsPayDifferential(
    payRate,
    differentialTrails.withBenefits
  );
  const weekendPayDifferential = getWeekendPayDifferential(
    payRate,
    differentialTrails.isWeekendShift
  );

  const exactPay = payRate?.exact;
  const minPay = payRate?.min;
  const maxPay = payRate?.max;
  if (!exactPay && minPay && maxPay && minPay !== maxPay) {
    return {
      type: "range" as const,
      overallPayMin: applyOverallDifferentialToPayRate(minPay, payRate, differentialTrails),
      overallPayMax: applyOverallDifferentialToPayRate(maxPay, payRate, differentialTrails),
      overallPayDifferential,
      shiftTypePayDifferential,
      benefitsPayDifferential,
      weekendPayDifferential,
    };
  }

  return {
    type: "single" as const,
    overallPay: applyOverallDifferentialToPayRate(
      exactPay ?? minPay ?? maxPay ?? 0,
      payRate,
      differentialTrails
    ),
    overallPayDifferential,
    shiftTypePayDifferential,
    benefitsPayDifferential,
    weekendPayDifferential,
  };
}

export function getPayRangeText(payRate: PayRate) {
  if (!payRate.min && !payRate.max && !payRate.exact) {
    return "";
  }

  if (isDefined(payRate?.exact)) {
    return "";
  }

  if (isDefined(payRate?.min) && isDefined(payRate?.max) && payRate?.min !== payRate?.max) {
    return "";
  }

  if (isDefined(payRate?.min) && !isDefined(payRate?.max)) {
    return "From";
  }

  if (isDefined(payRate?.max)) {
    return "Up to";
  }

  return "Pay";
}

export interface FormatPayRangeParams {
  payRate: PayRate;
  showPerHour: boolean;
  differentialTraits: GetDifferentialJobTraits;
}

export function formatPayRange(params: FormatPayRangeParams) {
  const { payRate, showPerHour, differentialTraits } = params;

  const exactPay = payRate?.exact;
  const minPay = payRate?.min;
  const maxPay = payRate?.max;
  const perHour = showPerHour ? " /hr" : "";

  if (!exactPay && !minPay && !maxPay) {
    return undefined;
  }

  const payBreakdown = getOverallPayBreakdown(payRate, differentialTraits);

  if (payBreakdown.type === "single") {
    return `${formatCentsAsUsd(payBreakdown.overallPay)}${perHour}`;
  }

  return `${formatCentsAsUsd(payBreakdown.overallPayMin)}${perHour} - ${formatCentsAsUsd(
    payBreakdown.overallPayMax
  )}${perHour}`;
}

export const DEFAULT_HIRE_PROFESSIONALS_QUALIFICATION_FILTER = {
  CNA: "CNA",
  "LVN/LPN": "LVN",
  RN: "RN",
  Caregiver: "CAREGIVER",
  HHA: "HHA",
  CHHA: "CHHA",
  "Dental Hygienist": "Dental Hygienist",
  "Dental Assistant": "Dental Assistant",
};

export function getAvailableWorkerTypesToFilter(workerLicensesData: Worker["licensesData"]) {
  if (!workerLicensesData) {
    return undefined;
  }

  const qualifications = workerLicensesData.map((license) => license.qualification);
  // eslint-disable-next-line unicorn/prefer-spread
  const uniqueQualifications = Array.from(new Set(qualifications));

  const filteredQualifications = uniqueQualifications.filter((qualification) =>
    Object.values(DEFAULT_HIRE_PROFESSIONALS_QUALIFICATION_FILTER).includes(qualification)
  );

  return filteredQualifications.length > 0 ? filteredQualifications : undefined;
}

export function buildPlacementsFilter({
  filter,
  showApplications,
  worker,
  searchByWorkplaceName,
}: {
  filter: GetPlacementsForWorkplaceQuery["filter"] | undefined;
  showApplications?: boolean;
  worker: Worker;
  searchByWorkplaceName?: string;
}) {
  if (!filter) {
    return undefined;
  }

  return {
    ...(showApplications
      ? { "applications.status": Object.values(PlacementApplicationStatus).join(",") }
      : {}),
    ...filter,
    distanceInMiles:
      isDefined(filter?.distanceInMiles) && filter.distanceInMiles > 0
        ? filter.distanceInMiles
        : DEFAULT_DISTANCE_FILTER_IN_MILES,
    workerTypes: isDefined(filter?.workerTypes)
      ? filter.workerTypes
      : getAvailableWorkerTypesToFilter(worker.licensesData)?.join(","),
    ...(searchByWorkplaceName ? { workplaceName: searchByWorkplaceName } : undefined),
  };
}

export function getFormattedInterviewDate(interview: PlacementIncludedInterview, timezone: string) {
  const startDateInTimezone = utcToZonedTime(interview.attributes.start, timezone);
  const endDateInTimezone = utcToZonedTime(interview.attributes.end, timezone);

  return `${format(startDateInTimezone, "MMM d, yyyy 'from' h:mm a")} - ${format(
    endDateInTimezone,
    "h:mm a"
  )}`;
}

export function getApplyButtonLabel(
  hasApplied: boolean,
  applicationCompleted: boolean,
  requiredInterview: boolean,
  bookedInterview: boolean
) {
  if (hasApplied) {
    if (applicationCompleted) {
      return "Applied";
    }

    if (requiredInterview && bookedInterview) {
      return "Booked interview";
    }

    return "Applied";
  }

  return "Apply to this Job";
}

export function getPlacementActionProps(props: {
  placementCandidate: PlacementCandidate;
  placementDetails: PlacementDetail;
  isLoading: boolean;
  isJobInterviewsEnabled: boolean;
  isJobsEnabledForOnboardingCandidates: boolean;
  onApply: () => void;
  onUploadLicense: () => void;
  onOpenBookInterview: () => void;
  onOpenRescheduleInterview: () => void;
  onCreateProfile: () => void;
}): PrimaryActionProps {
  const {
    placementCandidate,
    placementDetails,
    isLoading,
    isJobInterviewsEnabled,
    isJobsEnabledForOnboardingCandidates,
    onApply,
    onUploadLicense,
    onOpenBookInterview,
    onOpenRescheduleInterview,
    onCreateProfile,
  } = props;

  const hasApplied = hasAppliedToPlacement(placementDetails);

  const isStatusDisabledOrPending = [
    PlacementCandidateStatus.DISABLED,
    PlacementCandidateStatus.PENDING,
  ].includes(placementCandidate.status);
  const isJobsWithOnboardingCandidatesDisabled =
    !isJobsEnabledForOnboardingCandidates &&
    placementCandidate.status === PlacementCandidateStatus.ONBOARDING;

  if (isStatusDisabledOrPending || isJobsWithOnboardingCandidatesDisabled) {
    return {
      type: PLACEMENT_ACTION_TYPE.CREATE_PROFILE,
      label: "Create a Profile",
      onClick: onCreateProfile,
      disabled: false,
      isLoading: false,
    };
  }

  const licenseState = placementDetails.workplace.state;

  // If not eligible, show the "Upload valid license" button
  if (!placementDetails.isLicenseEligible) {
    return {
      type: PLACEMENT_ACTION_TYPE.UPLOAD_LICENSE,
      label: `Upload a valid ${licenseState} license`,
      warning: `You need a valid ${licenseState} license to access this job`,
      onClick: onUploadLicense,
      disabled: false,
      isLoading: false,
    };
  }

  const requiredInterview = interviewRequired(placementDetails);
  const bookedInterview = hasBookedInterview(placementDetails);
  const applicationCompleted = isApplicationCompleted(placementDetails);
  const showRescheduleInterviewButton =
    bookedInterview &&
    isJobInterviewsEnabled &&
    placementDetails.isLicenseEligible &&
    !applicationCompleted &&
    placementDetails.interviews?.some(
      (interview) =>
        interview.attributes.status === InterviewStatus.BOOKED &&
        isAfter(parseISO(interview.attributes.start), new Date())
    );

  if (showRescheduleInterviewButton) {
    return {
      type: PLACEMENT_ACTION_TYPE.RESCHEDULE_INTERVIEW,
      label: `Reschedule ${
        placementDetails.workplace.placementSettings?.isProspect ? "walk-in" : "interview"
      }`,
      onClick: onOpenRescheduleInterview,
      isLoading,
      disabled: isLoading,
    };
  }

  const showBookInterviewButton =
    (!hasApplied || !bookedInterview) &&
    requiredInterview &&
    isJobInterviewsEnabled &&
    !applicationCompleted &&
    placementDetails.isLicenseEligible;

  if (showBookInterviewButton) {
    return {
      type: PLACEMENT_ACTION_TYPE.BOOK_INTERVIEW,
      label: placementDetails.workplace.placementSettings?.isProspect
        ? "Schedule yourself as a walk-in"
        : "Schedule an in-person interview",
      onClick: onOpenBookInterview,
      isLoading,
      disabled: isLoading,
    };
  }

  return {
    type: PLACEMENT_ACTION_TYPE.APPLY,
    label: getApplyButtonLabel(
      hasApplied,
      applicationCompleted,
      requiredInterview,
      bookedInterview
    ),
    onClick: onApply,
    isLoading,
    disabled: hasApplied || isLoading,
  };
}

export function getCurrentWorkerTrialsDateRange() {
  const now = new Date();
  // round to prevent from triggering change detection on every render, which was spamming the API
  const nowRounded = roundToNearestMinutes(now, {
    nearestTo: 30,
    roundingMethod: "ceil",
  });

  return {
    start: nowRounded.toISOString(),
    end: addDays(nowRounded, 7).toISOString(),
  };
}
/* eslint-enable max-lines */
