import { RootPaths } from "@src/appV2/App/paths";

export const PLACEMENTS_BASE_PATH = "placements";
export const PLACEMENT_CANDIDATE_BASE_PATH = "placement-candidate";

export const PLACEMENT_WORKPLACE_PROFILE_PATH = "workplaces/:workplaceId/profile";
export const PLACEMENT_WORKPLACE_JOBS_PATH = "workplaces/:workplaceId/jobs";
export const PLACEMENT_WORKPLACE_REVIEWS_PATH = "workplace/:workplaceId/reviews";
export const PLACEMENT_WORKPLACE_REVIEWS_COMMENT_PATH = `workplace/:workplaceId/comment/:commentId`;
export const PLACEMENT_WORKPLACE_LEAVE_REVIEW_PATH = `workplace/:workplaceId/reviews/leave-review`;

export const PLACEMENT_PATHS = {
  ROOT: `${RootPaths.APP_V2_HOME}/${PLACEMENTS_BASE_PATH}`,
  DETAILS: `${RootPaths.APP_V2_HOME}/${PLACEMENTS_BASE_PATH}/:placementId/placement-candidate/:placementCandidateId?`,
  DETAILS_WITHOUT_PLACEMENT_CANDIDATE: `${RootPaths.APP_V2_HOME}/${PLACEMENTS_BASE_PATH}/:placementId`,
  CHAT: `${RootPaths.APP_V2_HOME}/${PLACEMENTS_BASE_PATH}/:placementId/workplace/:workplaceId/chat?source=:source`,
  WORKPLACE_PROFILE: `${RootPaths.APP_V2_HOME}/${PLACEMENTS_BASE_PATH}/${PLACEMENT_WORKPLACE_PROFILE_PATH}`,
  WORKPLACE_JOBS: `${RootPaths.APP_V2_HOME}/${PLACEMENTS_BASE_PATH}/${PLACEMENT_WORKPLACE_JOBS_PATH}?source=:source`,
  WORKPLACE_REVIEWS: `${RootPaths.APP_V2_HOME}/${PLACEMENTS_BASE_PATH}/${PLACEMENT_WORKPLACE_REVIEWS_PATH}`,
  WORKPLACE_REVIEWS_COMMENT: `${RootPaths.APP_V2_HOME}/${PLACEMENTS_BASE_PATH}/${PLACEMENT_WORKPLACE_REVIEWS_COMMENT_PATH}`,
  WORKPLACE_LEAVE_REVIEW: `${RootPaths.APP_V2_HOME}/${PLACEMENTS_BASE_PATH}/${PLACEMENT_WORKPLACE_LEAVE_REVIEW_PATH}`,
} as const;

export function getPlacementDetailsPath(placementId: string, placementCandidateId: string): string {
  return PLACEMENT_PATHS.DETAILS.replace(":placementId", placementId).replace(
    ":placementCandidateId",
    placementCandidateId
  );
}

export function getPlacementDetailsPathWithoutPlacementCandidate(placementId: string): string {
  return PLACEMENT_PATHS.DETAILS_WITHOUT_PLACEMENT_CANDIDATE.replace(":placementId", placementId);
}

export function getPlacementChatPath(
  placementId: string,
  workplaceId: string,
  source:
    | "application-success-details"
    | "chat-with-workplace"
    | "chat-with-placement-candidate-via-chat-channels"
    | "chat-with-placement-candidate-via-my-shifts"
): string {
  return PLACEMENT_PATHS.CHAT.replace(":placementId", placementId)
    .replace(":workplaceId", workplaceId)
    .replace(":source", source);
}

const WORKPLACE_PLACEMENTS_PATH_SOURCES = {
  WORKPLACE_PROFILE_MODAL: "workplace-profile-modal",
  WORKPLACE_PLACEMENTS_AD_CARD: "workplace-placements-ad-card",
  PLACEMENT_MAP_VIEW: "placement-map-view",
} as const;

export type WorkplacePlacementsPathSource =
  (typeof WORKPLACE_PLACEMENTS_PATH_SOURCES)[keyof typeof WORKPLACE_PLACEMENTS_PATH_SOURCES];

export function getWorkplacePlacementsPath(
  workplaceId: string,
  source: WorkplacePlacementsPathSource
): string {
  return PLACEMENT_PATHS.WORKPLACE_JOBS.replace(":workplaceId", workplaceId).replace(
    ":source",
    source
  );
}
