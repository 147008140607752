/* eslint-disable max-lines */
import { type IconType } from "@clipboard-health/ui-components";
import { API_TYPES } from "@src/appV2/api";
import { JobType, ShiftNameType } from "@src/appV2/redesign/PlacementCandidate/types";
import { InterviewStatus } from "@src/appV2/Shifts/MyShifts/api/useFetchPaginatedInterviews";
import { z } from "zod";

export const PLACEMENT_STATUS = ["draft", "open", "closed"] as const;
export type PlacementStatus = (typeof PLACEMENT_STATUS)[number];

export enum PlacementApplicationStatus {
  APPLIED = "applied",
  ACCEPTED = "accepted",
  INTERVIEW = "interview",
  OFFER = "offer",
  HIRED = "hired",
  REJECTED = "rejected",
}

export const placementApplicationStatusMap = {
  [PlacementApplicationStatus.APPLIED]: {
    label: "Applied",
    value: PlacementApplicationStatus.APPLIED,
  },
  [PlacementApplicationStatus.ACCEPTED]: {
    label: "Accepted",
    value: PlacementApplicationStatus.ACCEPTED,
  },
  [PlacementApplicationStatus.INTERVIEW]: {
    label: "Interview",
    value: PlacementApplicationStatus.INTERVIEW,
  },
  [PlacementApplicationStatus.OFFER]: {
    label: "Offer",
    value: PlacementApplicationStatus.OFFER,
  },
  [PlacementApplicationStatus.HIRED]: {
    label: "Hired",
    value: PlacementApplicationStatus.HIRED,
  },
  [PlacementApplicationStatus.REJECTED]: {
    label: "Rejected",
    value: PlacementApplicationStatus.REJECTED,
  },
};

export enum PlacementPerk {
  SHIFT_DIFFERENTIAL = "shiftDifferential",
  DAILY_PAY = "dailyPay",
  TWELVE_SHIFTS_AVAILABLE = "twelveShiftsAvailable",
  DOUBLES_AVAILABLE = "doublesAvailable",
  SIGN_ON_BONUS = "signOnBonus",
  OVERTIME_AVAILABLE = "overtimeAvailable",
}

export const PLACEMENT_PERKS: Array<{
  value: PlacementPerk;
  label: string;
  icon: IconType;
}> = [
  {
    value: PlacementPerk.SHIFT_DIFFERENTIAL,
    label: "Shift Differential",
    icon: "calendar-clock",
  },
  {
    value: PlacementPerk.DAILY_PAY,
    label: "Daily Pay",
    icon: "time-flies",
  },
  {
    value: PlacementPerk.TWELVE_SHIFTS_AVAILABLE,
    label: "12-Hour Shifts Available",
    icon: "date-clock",
  },
  {
    value: PlacementPerk.DOUBLES_AVAILABLE,
    label: "Double Shifts Available",
    icon: "clock-history",
  },
  {
    value: PlacementPerk.SIGN_ON_BONUS,
    label: "Sign-on Bonus",
    icon: "bolt-filled",
  },
  {
    value: PlacementPerk.OVERTIME_AVAILABLE,
    label: "Overtime Available",
    icon: "clock",
  },
];

export const placementsFilterSchema = z.object({
  distanceInMiles: z.number().optional(),
  workerTypes: z.string().optional(),
  jobTypes: z.string().optional(),
  shiftTypes: z.string().optional(),
  workplaceName: z.string().optional(),
  workplaceId: z.string().optional(),
  "applications.status": z.string().optional(),
});

export const getPlacementsForWorkplaceQuerySchema = z.object({
  filter: placementsFilterSchema.optional(),
  page: z
    .object({
      size: z.number().optional(),
      cursor: z.string().optional(),
    })
    .optional(),
});
export type GetPlacementsForWorkplaceQuery = z.infer<typeof getPlacementsForWorkplaceQuerySchema>;

export const placementDataSchema = z.object({
  type: z.literal(API_TYPES.placement),
  id: z.string(),
  attributes: z.object({
    status: z.enum(PLACEMENT_STATUS),
    title: z.string(),
    jobTypes: z.array(z.nativeEnum(JobType)),
    shiftTypes: z.array(z.nativeEnum(ShiftNameType)),
    workerTypes: z.array(z.string()).optional(),
    perks: z.array(z.nativeEnum(PlacementPerk)).optional(),
    validUntil: z.coerce.date().optional(),
    hiringPOC: z.object({
      name: z.string(),
      email: z.string(),
    }),
    originalJobLink: z.string().optional(),
    payRange: z
      .object({
        minPay: z
          .object({
            amountInMinorUnits: z.number(),
            currencyCode: z.string(),
          })
          .optional(),
        maxPay: z
          .object({
            amountInMinorUnits: z.number(),
            currencyCode: z.string(),
          })
          .optional(),
      })
      .optional(),
    signOnBonus: z
      .object({
        amountInMinorUnits: z.number(),
        currencyCode: z.string(),
      })
      .optional(),
    distance: z.number(),
    address: z.object({
      formatted: z.string().optional(),
    }),
    coordinates: z.array(z.number()),
  }),
  meta: z.object({
    isLicenseEligible: z.boolean(),
  }),
  relationships: z.object({
    workplace: z.object({
      data: z.object({
        type: z.literal(API_TYPES.workplace),
        id: z.string(),
      }),
    }),
    applications: z
      .object({
        data: z.array(
          z.object({
            type: z.literal(API_TYPES.placementApplication),
            id: z.string(),
          })
        ),
      })
      .optional(),
  }),
});

const placementWorkplaceIncludeSchema = z.object({
  type: z.literal(API_TYPES.workplace),
  id: z.string(),
  attributes: z.object({
    name: z.string(),
    state: z.string(),
    photos: z.array(z.string()).optional(),
    placementSettings: z
      .object({
        isProspect: z.boolean().optional(),
      })
      .optional(),
  }),
});
export type PlacementIncludedWorkplace = z.infer<typeof placementWorkplaceIncludeSchema>;
const placementApplicationIncludeSchema = z.object({
  type: z.literal(API_TYPES.placementApplication),
  id: z.string(),
  attributes: z.object({
    appliedDate: z.string(),
    status: z.nativeEnum(PlacementApplicationStatus),
  }),
});
export type PlacementIncludedApplication = z.infer<typeof placementApplicationIncludeSchema>;

const placementInterviewSettingsIncludeSchema = z.object({
  type: z.literal(API_TYPES.interviewSettings),
  attributes: z.object({
    scheduleInterviews: z.boolean(),
  }),
  relationships: z.object({
    workplace: z.object({
      data: z.object({
        type: z.literal(API_TYPES.workplace),
        id: z.string(),
      }),
    }),
  }),
});
export type PlacementIncludedInterviewSettings = z.infer<
  typeof placementInterviewSettingsIncludeSchema
>;

const placementInterviewIncludeSchema = z.object({
  type: z.literal(API_TYPES.interview),
  id: z.string(),
  attributes: z.object({
    start: z.string(),
    end: z.string(),
    status: z.nativeEnum(InterviewStatus),
  }),
  relationships: z.object({
    placementApplication: z.object({
      data: z.object({
        type: z.literal(API_TYPES.placementApplication),
        id: z.string(),
      }),
    }),
  }),
});
export type PlacementIncludedInterview = z.infer<typeof placementInterviewIncludeSchema>;

export const placementIncludeSchema = z.array(
  z.union([
    placementWorkplaceIncludeSchema,
    placementApplicationIncludeSchema,
    placementInterviewSettingsIncludeSchema,
    placementInterviewIncludeSchema,
  ])
);

export const paginatedGetPlacementsResponseSchema = z.object({
  data: z.array(placementDataSchema),
  included: placementIncludeSchema,
  links: z.object({
    nextCursor: z.string().optional(),
  }),
});

export type GetPlacementsResponse = z.infer<typeof paginatedGetPlacementsResponseSchema>;
export type PlacementData = z.infer<typeof placementDataSchema>;

export const placementResponseSchema = z.object({
  data: placementDataSchema,
  included: placementIncludeSchema,
});
export type PlacementResponse = z.infer<typeof placementResponseSchema>;
/* eslint-enable max-lines */
